import { cn } from "@lib/utils";
import type { InputHTMLAttributes } from "react";
import { forwardRef } from "react";
import styles from "./input.module.css";
export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  return (
    <input
      ref={ref}
      className={cn(
        "bg-background px-3 py-2 file:border-0 file:bg-transparent file:text-sm focus-visible:outline-none focus-visible:ring-2 disabled:cursor-not-allowed disabled:opacity-50",
        className,
        styles.input,
      )}
      type={type}
      {...props}
    />
  );
});
Input.displayName = "Input";

export { Input };
